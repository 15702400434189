import * as React from 'react';
import { toast } from 'sonner';

import { twMerge } from 'tailwind-merge';

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {}

const Input = React.forwardRef<HTMLInputElement, InputProps>(({ className, type, onChange, ...props }, ref) => {
	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (/[^\u0000-\u00FF]/.test(e.target.value)) toast.error('Não permitido Emojis ou caracteres especiais! ', { position: 'top-right' });
		if (onChange) {
			onChange(e);
		}
	};
	return (
		<input
			type={type}
			className={twMerge(
				'file:bg-transparent flex h-10 w-full rounded-md border border-input bg-[transparent] px-3 py-2 text-sm font-medium text-white opacity-100 ring-offset-background transition-all duration-100 file:border-0 file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50',
				className,
			)}
			ref={ref}
			onChange={handleChange}
			{...props}
		/>
	);
});
Input.displayName = 'Input';

export { Input };
