import { CircularProgress } from '@mui/material';
import { motion } from 'framer-motion';
import lampada from '../../assets/image/lampada.png';

const loadingContainer = {
	color: 'white',
	display: 'flex',
	alignItems: 'flex-end',
};

const loadingDot = {
	backgroundColor: 'white',
	width: '.5rem',
	height: '.5rem',
	borderRadius: '50%',
	marginRight: '3px',
	marginBottom: '0.6rem',
};

const loadingLetter = {
	display: 'block',
};

const BounceContainerVariants = {
	start: {
		transition: { staggerChildren: 0.1 },
	},
	end: {
		transition: { staggerChildren: 0.1 },
	},
};

const BounceVariants = {
	start: { opacity: 0, y: 50 },
	end: { opacity: 1, y: 0 },
};

export default function LoadingPage() {
	return (
		<div className="flex h-screen w-full flex-col items-center justify-center p-10 sm:flex-row">
			<div className="mr-7 flex">
				<img src={lampada} width={150} alt="lampada" className="z-50" />
			</div>
			<motion.div
				style={loadingContainer}
				variants={BounceContainerVariants}
				transition={{ staggerChildren: 0.1 }}
				initial="start"
				animate="end"
				className="flex justify-center text-5xl sm:mt-[5rem] sm:text-6xl"
			>
				<motion.span style={loadingLetter} variants={BounceVariants} transition={{ duration: 0.4, yoyo: Infinity, ease: 'easeInOut' }}>
					C
				</motion.span>
				<motion.span style={loadingLetter} variants={BounceVariants} transition={{ duration: 0.4, yoyo: Infinity, ease: 'easeInOut' }}>
					a
				</motion.span>
				<motion.span style={loadingLetter} variants={BounceVariants} transition={{ duration: 0.4, yoyo: Infinity, ease: 'easeInOut' }}>
					r
				</motion.span>
				<motion.span style={loadingLetter} variants={BounceVariants} transition={{ duration: 0.4, yoyo: Infinity, ease: 'easeInOut' }}>
					r
				</motion.span>
				<motion.span style={loadingLetter} variants={BounceVariants} transition={{ duration: 0.4, yoyo: Infinity, ease: 'easeInOut' }}>
					e
				</motion.span>
				<motion.span style={loadingLetter} variants={BounceVariants} transition={{ duration: 0.4, yoyo: Infinity, ease: 'easeInOut' }}>
					g
				</motion.span>
				<motion.span style={loadingLetter} variants={BounceVariants} transition={{ duration: 0.4, yoyo: Infinity, ease: 'easeInOut' }}>
					a
				</motion.span>
				<motion.span style={loadingLetter} variants={BounceVariants} transition={{ duration: 0.4, yoyo: Infinity, ease: 'easeInOut' }}>
					n
				</motion.span>
				<motion.span style={loadingLetter} variants={BounceVariants} transition={{ duration: 0.4, yoyo: Infinity, ease: 'easeInOut' }}>
					d
				</motion.span>
				<motion.span style={loadingLetter} variants={BounceVariants} transition={{ duration: 0.4, yoyo: Infinity, ease: 'easeInOut' }}>
					o
				</motion.span>
				<motion.span style={loadingDot} variants={BounceVariants} transition={{ duration: 0.4, yoyo: Infinity, ease: 'easeInOut' }}></motion.span>
				<motion.span style={loadingDot} variants={BounceVariants} transition={{ duration: 0.4, yoyo: Infinity, ease: 'easeInOut' }}></motion.span>
				<motion.span style={loadingDot} variants={BounceVariants} transition={{ duration: 0.4, yoyo: Infinity, ease: 'easeInOut' }}></motion.span>
			</motion.div>
		</div>
	);
}

export function LoadingCircle() {
	return (
		<div className="mx-auto flex w-full items-center justify-center overflow-hidden">
			<CircularProgress className="mx-auto flex items-center justify-center" color={'success'} size={40} />
		</div>
	);
}
