import { StatusConnectionProvider } from '@/contexts/pages/StatusConnection/StatusConnection';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Toaster } from 'sonner';
import { ThemeProvider } from './components/componentsCN/ui/theme-provider';
import { AuthProvider } from './contexts/AuthContext';
import Rotas from './routes/';

const queryClient = new QueryClient();
function App() {
	return (
		<QueryClientProvider client={queryClient}>
			<StatusConnectionProvider>
				<ThemeProvider defaultTheme="dark" storageKey="vite-ui-theme">
					<AuthProvider>
						<Rotas />
						<Toaster
							theme="dark"
							richColors
							toastOptions={{
								classNames: {
									title: 'font-medium',
									toast: 'group toast p-4 group-[.toaster]:bg-background group-[.toaster]:text-foreground group-[.toaster]:border-border group-[.toaster]:shadow-lg',
								},
							}}
							pauseWhenPageIsHidden
						/>
					</AuthProvider>
				</ThemeProvider>
			</StatusConnectionProvider>
		</QueryClientProvider>
	);
}

export default App;
